/** 通知订阅场景 */
export const SCENE = {
  /** 反馈被管理员回复 */
  POST: 'replied_post_by_admin',
  /** 评论被管理员回复 */
  REPLY: 'replied_reply_by_admin',
};
/** 通知订阅场景 NOTE: 这里和后端沟通过，感觉有些冗余，但后端执意要，算了先放放 */
export const TYPE = {
  /** 反馈被管理员回复 */
  POST: 'post',
  /** 评论被管理员回复 */
  REPLY: 'reply',
};

/** 通知渠道 */
export const METHOD = {
  /** 微信公众号-订阅通知 */
  wx_subscribe: 'wx_subscribe',
  /**  微信公众号-一次性订阅通知 */
  wx_subscribe_one_time: 'wx_subscribe_one_time',
  /**  微信公众号-一次性订阅通知-兔小巢定制 */
  wx_subscribe_one_time_txc: 'wx_subscribe_one_time_txc',
  /** 微信小程序订阅消息 */
  wx_mini_subscribe: 'wx_mini_subscribe',
  /** QQH5重定向 */
  qq_redirect: 'qq_redirect',
  /** QQ小程序订阅消息 */
  qq_mini_subscribe: 'qq_mini_subscribe',
  /** 邮件 */
  email: 'email',
  /** 微信模板消息 */
  wx_template: 'wx_template',
  /** webhook订阅 */
  webhook: 'webhook',
};

/** 邮件通知的订阅状态 */
export const EMAIlSTATUS = {
  /** 未验证 */
  unverified: 'unverified',
  /** 待验证 */
  pending_verified: 'pending_verified',
  /** 已验证 */
  verified: 'verified',
};

/** qq里腾讯客服地址 */
export const QQ_REDIRECT_URL = 'https://post.mp.qq.com/tmpl/default/client/article/html/jump.html?action=accountCard&puin=3190127506';
