export const POST_TAB = {
  BUG: 'BUG',
  FEATURE: 'FEATURE',
  IWANT: 'IWANT',
};


export const I_WANT_TAB = [
  {
    key: POST_TAB.FEATURE,
    text: 'Feature',
    cText: '提建议',
  }, {
    key: POST_TAB.BUG,
    text: 'Bug',
    cText: '提缺陷',
  },
  {
    key: POST_TAB.IWANT,
    text: 'I want it',
    cText: '我想要',
  },
];
