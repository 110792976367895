import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { cloneDeep, trim, isEmpty } from 'lodash-es';
import '@tencent/coral-style';
import { CascadeAdvance } from '@tencent/coral-design';
import message from '@tencent/ten-design-react/lib/message';
import IWantForm from 'components/i-want/i-want-form';
import { getApiV3RoadmapSettingStatus } from 'components/api';
import { Modal } from 'components/modal';
import { UserEnter } from 'components/user-enter';
import { Avatar } from 'components/user';
import { METHOD } from 'components/notification/constants';
import WxSubscripeContent from './wx-subscripe-content';
import { EmailSubscripeContent } from './email-subscripe-content';
import { POST_TAB, I_WANT_TAB } from './contants';
import { getProductId } from 'components/util';
import { POST_TYPE } from 'components/constants/app';
import { Checkbox, Tooltip } from 'tdesign-react';
import { InfoCircleIcon } from 'tdesign-icons-react';
import 'tdesign-react/es/style/index.css';
import './style.less';
class PostPopUps extends Component {
  constructor(props) {
    super(props);

    this.productId = getProductId();
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      accpet: Object.assign([], props.fields_group),
      problemArr: [], // 问题列表
      showLabel: [true, true, true],
      limitTextLen: 500,
      isShowCascadeAdvance: false,
      tab: POST_TAB.FEATURE,
      // 默认的post内容
      defaultPost: '',
      // 默认的i-want 标题
      defaultWantTitle: '',
      // 默认的i-want 目前痛点
      defaultWantContent: '',
      // 默认的i-want 解决方案
      defaultWantSolution: '',
      // 是否显示话题选择
      showSelectTopic: false,
      // 默认话题
      defaultTopic: null,
    };

    this.labels = React.createRef();
    this.userEnterRef = React.createRef();
    this.iwantRef = React.createRef();
    this.selectTopicRef = React.createRef();
    this.getTopicSetting();
    this.inpVal = [];
    this.errTxt = [];
  }

  // 获取产品的话题设置
  getTopicSetting = () => {
    getApiV3RoadmapSettingStatus({ productId: this.productId })
      .then((res) => {
        if (res.status === 0) {
          this.setState({ showSelectTopic: res.data?.status === 'on' });
        }
      });
  }

  onSubmit(val, images, topic) {
    const { props, state } = this;

    if (props.preview === '1') {
      message.error('预览模式下不能完成该操作');
      return Promise.resolve();
    }

    if (val.length > state.limitTextLen) {
      return Promise.resolve();
    }

    // 记录必填项
    const inpVal = [];
    const errTxt = [];
    const fields = [];
    for (let i = 0; i < props.fields.length; i++) {
      const item = props.fields[i];
      const val = trim(this.inpVal[i].value);

      this.inpVal[i].classList.remove('inp_desable');
      this.errTxt[i].innerText = '';

      if (item.necessity && isEmpty(val)) {
        inpVal.push(this.inpVal[i]);
        errTxt.push(this.errTxt[i]);
        fields.push(item);
      }
    }

    if (inpVal.length) {
      const { name } = fields[0];
      inpVal[0].classList.add('inp_desable');
      errTxt[0].innerText = `请填写${name}`;
      return Promise.resolve(false);
    }

    const userContactsFields = props.fields.map((item, i) => {
      const val = trim(this.inpVal[i].value);

      return {
        id: item.id,
        name: item.name,
        value: val,
      };
    });

    const userContactsFieldsGroup = state.accpet.map(item => (item.checked === 1
      ? { ...item, value: item.checked } : item));

    const userContacts = userContactsFields.concat(userContactsFieldsGroup.filter(item => item.checked === 1));

    const categories = this.getCategories();

    return props.onSubmit(
      val, images, userContacts, categories,
      state.tab === POST_TAB.FEATURE ? POST_TYPE.FEATURE : POST_TYPE.BUG,
      topic,
    );
  }

  getCategories = () => {
    const { problemArr } = this.state;
    const { categories } = this.props;
    if (!Array.isArray(categories?.children)) return;
    let defaultChild = categories.children;
    return problemArr.map((problem, index) => {
      const pathArr = problem.path.split('-');
      const categoryIndex = pathArr[index];
      const res = defaultChild[categoryIndex];
      defaultChild = res.children || [];
      return res;
    });
  }

  onChangeAccept(v) {
    const { accpet } = this.state;
    this.setState({
      accpet: accpet.map(item => (item.id === v.id ? { ...item, checked: Number(!item.checked) } : item)),
    });
  }

  getSelectData(data) {
    if (!data) return;
    const list = Array.from(data.children);
    const deep = 3;
    function tpl(pri, curDeep, item) {
      if (curDeep >= deep) return '';
      return {
        key: pri,
        text: item.value,
        value: item.value,
        path: pri,
        status: 'NO_CHECKBOX',
        children: item.children.length ? (item.children.map((item2, index2) => tpl(`${pri}-${index2}`, curDeep + 1, item2)) || '') : '',
      };
    }

    return list.map((item, index) => {
      const curDeep = 0;
      return tpl(String(index), curDeep, item);
    });
  }

  getSelectHead(data) {
    if (!data) return;
    if (data.children.length === 0) return;

    const titleArr = [];
    titleArr.push(data.title);
    function getTitle(child = []) {
      if (child[0].children.length) {
        titleArr.push(child[0].title);
        getTitle(child[0].children);
      }
    }
    getTitle(data.children);
    return titleArr;
  }


  changeHandler(path, data) {
    const { state } = this;
    // 遍历出当前选择的问题
    const findProblem = (curData, arr = []) => {
      for (let i = 0; i < curData.length; i++) {
        const item = curData[i];
        if (item.path === path) {
          arr.push({
            path: item.path,
            text: item.text,
            endNode: item.children.length === 0,
          });
        }
        if (item.children.length > 0) {
          findProblem(item.children, arr);
        }
      }
      return arr;
    };

    const curProblem = findProblem(data, []);
    // 判断是否为末级节点，选中末级节点后自动收起选择器
    const isEndNode = curProblem[0].endNode;
    isEndNode === true && this.setState({ isShowCascadeAdvance: false });

    // 通过 path 判断是否选过同级别的问题，若选过同级问题则返回其 index，并更新问题列表
    const sameLevelIndex = state.problemArr.findIndex(i => i.path.split('-').length === path.split('-').length);
    if (sameLevelIndex >= 0) {
      this.setState({
        problemArr: state.problemArr.slice(0, sameLevelIndex).concat(findProblem(data, [])),
      });
    } else {
      this.setState({
        problemArr: state.problemArr.concat(curProblem),
      });
    }
  }

  showProblem() {
    const { state } = this;
    return state.problemArr.map(item => (
      <span className="problem_span" key={item.path}>
        {item.text}
        <b></b>
      </span>
    ));
  }

  onBlur(val, index) {
    if (!val) {
      this.setState(prevState => ({
        showLabel: {
          ...prevState.showLabel,
          [index]: true,
        },
      }));
    }
  }

  onFocus(index) {
    const labels = document.querySelectorAll('.fields_item_label');
    if (labels[index]) {
      this.inpVal[index].style.paddingLeft = `${labels[index].offsetWidth + 15}px`;
    }
    this.setState(prevState => ({
      showLabel: {
        ...prevState.showLabel,
        [index]: false,
      },
    }));
  }

  changeTab = key => () => {
    const { tab } = this.state;
    if (tab === key) return;
    const newState = { tab: key };
    let topic = null;
    //  切到iwant  要给post设置默认值
    if (key === POST_TAB.IWANT) {
      const post = this.userEnterRef.current && this.userEnterRef.current.getTextValue();
      topic = this.userEnterRef.current.getTopicValue();
      newState.defaultPost = post;
    }
    // 从我想要tab切走需要存下我想要的输入内容
    if (tab === POST_TAB.IWANT) {
      const { title, content, solution } = this.iwantRef.current.getTextValue();
      topic = this.iwantRef.current.getTopicValue();
      newState.defaultWantTitle = title;
      newState.defaultWantContent = content;
      newState.defaultWantSolution = solution;
    }

    newState.defaultTopic = topic;
    this.setState(newState);
  }

  renderPostForm() {
    const { state, props, getSelectData } = this;

    if (state.tab === POST_TAB.IWANT) {
      return null;
    }
    const { defaultPost, showSelectTopic, defaultTopic } = this.state;
    const selectData = getSelectData(props.categories);
    const selectHead = this.getSelectHead(props.categories);
    const isShowCategory = props.categories && props.categories.isEnable && props.categories.children.length > 0;

    return (
      <div className={classNames('post_form', {
        post_form__want: props.showIwant,
      })}>
        {/* 选择问题分类 */}
        {isShowCategory && !props.showPostPopupSuccess
          && <div className="post_form_select">
            <div className="choose_problem"
              role="button"
              tabIndex={0}
              onKeyDown={() => this.setState({ isShowCascadeAdvance: !state.isShowCascadeAdvance })}
              onClick={() => this.setState({ isShowCascadeAdvance: !state.isShowCascadeAdvance })}
            >
              <b className="select_btn"></b>
              {
                state.problemArr.length
                  ? this.showProblem()
                  : '选择问题分类'
              }
            </div>
            {state.isShowCascadeAdvance && (<CascadeAdvance
              config={{ openSearch: false }}
              data={selectData}
              head={selectHead}
              onPathChange={path => this.changeHandler(path, selectData)}
            />)}
          </div>
        }
        {/* {Boolean(props.postType === 'reply') && props.replier} */}
        {!props.showPostPopupSuccess && <div className="post_form_userEnter">
          <UserEnter
            ref={this.userEnterRef}
            placeholder={props.textPlaceholder}
            topic={props.topic}
            isPc={true}
            textbtn={'发布'}
            defaultPost={defaultPost}
            defaultTopic={defaultTopic}
            formDataType={props.formDataType}
            limitText={state.limitTextLen}
            onlyNumber={true}
            onUploadImage={props.onUploadImage}
            onError={props.onTextEnterError}
            onSubmit={this.onSubmit}
            showSelectTopic={showSelectTopic}
          />
          {
            Boolean(props.fields.length)
            && <div className="post_form_fields">
              {
                props.fields.map((item, i) => (
                  <div className={`fields_item ${item.necessity ? 'fields_must' : ''}`} key={item.id}>
                    <input
                      className="fields_item_input"
                      type="text"
                      ref={(c) => {
                        this.inpVal[i] = c;
                      }}
                      onFocus={() => this.onFocus(i)}
                      onBlur={() => this.onBlur(this.inpVal[i].value, i)}
                    />
                    <p
                      className="fields_item_err_text"
                      ref={(c) => {
                        this.errTxt[i] = c;
                      }}
                    >
                    </p>
                    <span className="fields_item_label" ref={this.labels}>
                      {item.name}
                    </span>
                  </div>
                ))
              }
            </div>
          }

        </div>}
        {!props.showPostPopupSuccess && <div className="post_form_button">
          {
            Boolean(state.accpet.length)
            && <div className="post_form_checked">
              {state.accpet.map((item, i) => (
                <Checkbox key={i} className="post_form_checkItem"
                  checked={Boolean(item.checked)} onChange={() => this.onChangeAccept(item)}>
                  {item.name}
                  {item.tip
                  && <Tooltip
                    content={item.tip}
                    destroyOnClose={false}
                    placement="bottom"
                    showArrow
                    theme="light"
                  >
                    <InfoCircleIcon className="post_form_icon" size={15} />
                  </Tooltip>
                  }
                </Checkbox>
              ))}
            </div>
          }
        </div>}
        {this.renderSubcripe()}
      </div>
    );
  }

  renderIwantIcon() {
    const { tab } = this.state;
    if (tab !== POST_TAB.IWANT) {
      return null;
    }
    return <div className="post_pop_ups__emoji" />;
  }

  renderIwant() {
    const {
      tab,
      defaultWantTitle,
      defaultWantSolution,
      defaultWantContent,
      showSelectTopic,
      defaultTopic,
    } = this.state;
    const { onTextEnterError, onUploadImage, onSubmitSuccess, topic, showPostPopupSuccess } = this.props;
    if (tab !== POST_TAB.IWANT) {
      return null;
    }

    return (
      <div className={classNames('post_form', {
        post_form__want: true,
      })}>
        {!showPostPopupSuccess && (
          <IWantForm
            defaultTitle={defaultWantTitle}
            defaultContent={defaultWantContent}
            defaultSolution={defaultWantSolution}
            ref={this.iwantRef}
            onError={onTextEnterError}
            onSubmitSuccess={onSubmitSuccess}
            onUploadImage={onUploadImage}
            topic={topic}
            defaultTopic={defaultTopic}
            showSelectTopic={showSelectTopic}
          />
        )}
        {this.renderSubcripe()}
      </div>
    );
  }

  /** I want 需求临时接入 通过showIwant开关控制展示隐藏所有逻辑 */
  renderTab() {
    const { showIwant } = this.props;
    const { tab } = this.state;
    const { showPostPopupSuccess } = this.props;
    if (!showIwant || showPostPopupSuccess) {
      return null;
    }
    return (
      <div className="post_pop_ups__tab">
        {I_WANT_TAB.map(item => (
          <div
            className={classNames('post_pop_ups__tab-item', {
              'post_pop_ups__tab-item--active': tab === item.key,
            })}
            key={item.key}
            onClick={this.changeTab(item.key)}
            onKeyPress={this.changeTab(item.key)}
            role="button"
            tabIndex="0"
          >
            {item.cText}
          </div>
        ))}
      </div>
    );
  }

  renderSubcripe() {
    const { props } = this;
    return (
      <>
        {
          props.showPostPopupSuccess
          && METHOD.wx_subscribe === props.methodPc
          && <WxSubscripeContent {...props.postPopupSuccess} />
        }
        {props.showPostPopupSuccess
          && METHOD.email === props.methodPc
          && (
            <EmailSubscripeContent
              {...props.userData}
              scene={props.scene}
              onClose={props.onClose}
              handlEmailConfirm={props.handlEmailConfirm}
            />
          )
        }
      </>
    );
  }

  render() {
    const { props } = this;
    return (
      <Modal onClose={props.onClose}>
        <div className={classNames('post_pop_ups', { batchReply: props.batchReply })}>
          {/* banner */}
          <div className="banner">
            <Avatar src={props.userData.avatar} />
          </div>
          {this.renderIwantIcon()}
          {this.renderTab()}
          {this.renderPostForm()}
          {this.renderIwant()}
        </div>
      </Modal>
    );
  }
}

PostPopUps.propTypes = {
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onClose: PropTypes.func,
  handlEmailConfirm: PropTypes.func,
  onTextEnterError: PropTypes.func,
  onUploadImage: PropTypes.func,
  userData: PropTypes.object,
  categories: PropTypes.object,
  showPostPopupSuccess: PropTypes.bool,
  postPopupSuccess: PropTypes.bool,
  showIwant: PropTypes.bool,
  preview: PropTypes.bool,
  batchReply: PropTypes.bool,
  fields_group: PropTypes.array,
  fields: PropTypes.array,
  methodPc: PropTypes.string,
  scene: PropTypes.string,
  postType: PropTypes.string,
  formDataType: PropTypes.string,
  textPlaceholder: PropTypes.string,
  topic: PropTypes.object,
  defaultPost: PropTypes.string,
};
PostPopUps.defaultProps = {
  fields: [],
  fields_group: [],
  userData: {},
  replier: '',
  showIwant: false,
  onClose: () => console.log('onClose 没有'),
  onVerificationCode: () => console.log('onVerificationCode 没有'),
  onSubmit: () => console.log('onSubmit 没有'),
  onUploadImage: () => console.log('onUploadImage 没有'),
  postType: '',
  textPlaceholder: '',
  formDataType: 'post',
  batchReply: false,
};
export { PostPopUps };

