import React from 'react';
import QRCode from 'qrcode.react';
import WxImg from './img/weixin-new.png';
import { TcIconRefresh } from 'components/IconSystem';
import PropTypes from 'prop-types';
import './style.less';

const GetContent = (props) => {
  const { url, status } = props;

  if (status === 'subscribed') {
    return (
      <div className="pic">
        <span>授权成功</span>
      </div>
    );
  }
  return (
    <div className="pic qr_code">
      <div className="badge">
        <img src={WxImg} alt="WxImg" />
      </div>
      <QRCode size={124} level="L" value={url} />
      {status === 'error' && (
        <div className="error" onKeyDown={props.onRetry} onClick={props.onRetry} role="button" tabIndex="0">
          <TcIconRefresh />
          <p>二维码失效</p>
          <p>请点击重试</p>
        </div>
      )}
    </div>
  );
};

const WxSubscripeContent = (props) => {
  // 这里根据是否有微信二维码的URL来判断
  const showQRCode = Boolean(props.url);

  return (
    <div className="post-popup-success">
      <p className="popup-success__thank">发布成功，请扫码接收回复通知</p>
      {showQRCode && <p className="popup-success__reply">扫码授权第一时间收到管理员回复通知</p>}
      {showQRCode && <GetContent {...props} />}
    </div>
  );
};

WxSubscripeContent.defaultProps = {
  url: '',
};
GetContent.propTypes = {
  url: PropTypes.string,
  status: PropTypes.string,
  onRetry: PropTypes.func,
};
WxSubscripeContent.propTypes = {
  url: PropTypes.string,
};
export default React.memo(WxSubscripeContent);
