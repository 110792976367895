
// 兔小巢scheme, 主要是对开启的产品进行广播
const PROTOCOL = 'txc:';
/** 动作 */
enum ACTION {
  /** 提交 */
  SUBMIT = 'submit'
}

/** 操作类型 */
enum SERVER {
  /** 发帖 */
  POST = 'post',
  /** 发帖 */
  I_WANT = 'i-want'
}
interface SendPostParams {
  productId: string | number;
  contacts: { name: string, value: string | number }[];
}
interface GetUrlParams {
  action: ACTION;
  server: SERVER
}

// TODO 稳定后挪到后台成为通用能力
const whiteList = [
  // 【无登录态】让用户必填QQ号
  // QQ登录意见反馈
  '429222',
  // 【有登录态】中转页自动获取QQ号
  // 手机QQ_IOS
  '36456',

  // 手机QQ_android
  '36028',

  // 频道：
  '364833',

  // 频道_Android
  '343383',

  // 频道_iOS
  '343381',

  // 小世界_Android
  '342658',

  // 小世界_iOS
  '342626',

  // 超级QQ秀_android
  '367198',

  // 超级QQ秀_IOS
  '367194',
  // 正式环境测试产品
  '315704',
  '436269', // 阅文阅读- Android
  '436271', // 阅文阅读_iOS
  '1918', // 阅文阅读- Android
  '14482', // 阅文阅读_iOS
  '42324', // 腾讯会议
  '538648', // 腾讯会议测试
];
class TxcUrlScheme {
  getUrl = ({ action, server }: GetUrlParams) => `${PROTOCOL}//${server}/${action}`

  jump = ({ action, search, server }) => {
    const url = this.getUrl({ action, server });
    // window.location.href = url + search; // NOTE: location.href 方案在iOS上会有异常弹窗，

    const iframe = document.createElement('iframe');

    iframe.src = url + search;

    iframe.style.display = 'none';

    document.body.appendChild(iframe);
  }

  sendPost = ({ productId, contacts }: SendPostParams) => {
    if (!whiteList.includes(`${productId}`)) return;
    const contactsString = contacts
      // 输入的情况可能没有value， 传空字符串
      .map((contact) => {
        if (contact.value === undefined) {
          return { name: contact?.name, value: '' };
        }
        return contact;
      })
      .map(contact => `${contact.name}=${contact.value}`)
      .join('&');
    const search = `?version=1&src_type=web&product_id=${productId}&${contactsString}`;

    this.jump({ action: ACTION.SUBMIT, server: SERVER.POST, search });
  }

  sendIwant = ({ productId }: SendPostParams) => {
    if (!whiteList.includes(`${productId}`)) return;

    const search = `?version=1&src_type=web&product_id=${productId}`;

    this.jump({ action: ACTION.SUBMIT, server: SERVER.I_WANT, search });
  }
}


export default TxcUrlScheme;
