import React, { Component, Fragment } from 'react';
import { message } from '@tencent/ten-design-react';
import {
  postApiNotificationUserVerifySend,
  postApiNotificationUserVerifyCode,
  getApiNotificationSubscribeInfo,
} from 'components/api';
import { getProductId } from 'components/util';
import PropTypes from 'prop-types';
import './style.less';
import { TYPE, SCENE, METHOD, EMAIlSTATUS } from '../notification/constants';

class EmailSubscripeContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successEmail: '',
      successEmailCode: '',
      cIndex: false,
      loading: false,
      yztime: 59,
    };
  }

  componentDidMount() {
    this.isSubscribed();
  }

  getProductIdFun() {
    return getProductId();
  }

  handleEmailChange = (e) => {
    this.setState({ successEmail: e.target.value });
  }
  handleEmailCodeChange = (e) => {
    this.setState({ successEmailCode: e.target.value });
  }

  count = () => {
    let { yztime } = this.state;
    const siv = setInterval(() => {
      this.setState({ yztime: (yztime -= 1) }, () => {
        if (yztime <= -1) {
          clearInterval(siv);
          this.setState({ loading: false, yztime: 59 });
        }
      });
    }, 1000);
  }

  onVerificationEmailCode = () => {
    if (!(/^[a-zA-Z0-9_.-]+@+[a-zA-Z0-9]+.+[A-z]/.test(this.state.successEmail))) {
      message.error('请输入正确的邮箱地址');
      return;
    }
    this.setState({ loading: true });
    if (!this.state.yztime === false) this.count();
    postApiNotificationUserVerifySend({
      productId: parseInt(this.getProductIdFun(), 10),
      channel: METHOD.email,
      channel_uid: this.state.successEmail,
      type: 'code',
    }).then((res) => {
      console.log(res);
    })
      .catch((error) => {
        if (error.message === '你的操作频率过快啦，稍微缓口气吧') message.error(error.message);
      });
  }

  onVerificationCode = () => {
    if (!this.state.successEmailCode && this.state.successEmailCode === '') {
      message.error('请输入验证码');
      return;
    }
    postApiNotificationUserVerifyCode({
      productId: parseInt(this.getProductIdFun(), 10),
      code: this.state.successEmailCode,
      channel: METHOD.email,
      channel_uid: this.state.successEmail,
    }).then((res) => {
      this.setState({ cIndex: true });
      console.log(res);
    })
      .catch((error) => {
        if (error.message === '无效的验证码') message.error(error.message);
      });
  }

  isSubscribed = () => {
    getApiNotificationSubscribeInfo(parseInt(this.getProductIdFun(), 10), {
      channel: METHOD.email,
      scene: this.props.scene === TYPE.REPLY ? SCENE.REPLY : SCENE.POST,
    })
      .then((res) => {
        const emailInfo = res.data.email_info.email_info;
        if (emailInfo.status === EMAIlSTATUS.verified) this.setState({ cIndex: true, successEmail: emailInfo.email });
      });
  }

  onVerificationModify = () => {
    this.setState({ cIndex: false });
  }

  confirmEmail = (event) => {
    this.props.handlEmailConfirm(event);
  }

  render() {
    const { state } = this;

    return (
      <div className="post-popup-success">
        <p className="popup-success__thank">发布成功，请填写邮箱信息</p>
        <p className="popup-success__reply">通过邮箱接收管理员回复推送</p>
        {!state.cIndex ? <Fragment>
          <p>
            <input className="popup-success__input" placeholder="填写你的电子邮箱地址" type="text" onChange={this.handleEmailChange} value={state.successEmail}></input>
            <button className="popup-success__button popup-success__button--default" onClick={this.onVerificationEmailCode} >
              {state.loading ? `${state.yztime}秒` : '发送验证码'}
            </button>
          </p>
          <p className="popup-success__email">
            <input className="popup-success__input" placeholder="验证码" type="text" onChange={this.handleEmailCodeChange} value={state.successEmailCode}></input>
            <button className="popup-success__button popup-success__button--primary" onClick={this.onVerificationCode}>完成验证</button>
          </p>
        </Fragment>
          : <Fragment>
            <p>
              <input className="popup-success__input popup-success__input--weight" type="text" value={state.successEmail} disabled></input>
              <span onKeyDown={this.onVerificationModify} className="popup-success__span" onClick={this.onVerificationModify}role="button" tabIndex="0">修改</span>
            </p>
            <p className="popup-success__email">
              {/* <buttonclassName="popup-success__button"onClick={event=>props.onClose(event)}>跳过</button> */}
              <button className="popup-success__button popup-success__button--primary" onClick={this.confirmEmail}>确认订阅</button>
            </p>
          </Fragment>}
      </div>
    );
  }
}

EmailSubscripeContent.propTypes = {
  scene: PropTypes.string,
  onClose: PropTypes.func,
  handlEmailConfirm: PropTypes.func,
};

export { EmailSubscripeContent };
