import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Tag from 'components/tag';

import { Loading } from './loading';
import './style.less';


const observerHandler = entries => {
    entries.forEach(entry => {
        if (!entry.isIntersecting) {
            return;
        }

        entry.target && entry.target.onShow();
    });
};

const LoadMoreHook = props => {
    const el = useRef(null);

    useEffect(() => {
        const element = el.current;

        // 关联 props.onShow 到元素上
        element.onShow = props.onShow;

        LoadMoreObserver.observe(element);
        return () => {
            LoadMoreObserver.unobserve(el.current);
        };

    // 这里通过监听props的变化来使useState的值变化
    }, [props]);

    return (
        <Tag.div className="load-more" ref={el}>
            <Loading /> <Tag.span isText className="load-more-text">正在加载...</Tag.span>
        </Tag.div>
    );
};

LoadMoreHook.defaultProps = {
    onShow() {
        console.log('LoadMoreHook 的 onShow 未绑定');
    }
};

LoadMoreHook.propTypes = {
    onShow: PropTypes.func
};

const LoadMoreObserver = (function () {
    // 需要用 window.IntersectionObserver 而不能直接 IntersectionObserver，否则 iOS9 会报错
    // ReferenceError: Can't find variable: IntersectionObserver
    if (!window.IntersectionObserver) {
        console.log(
            'LoadMoreHook 依赖 IntersectionObserver API，当前 runtime 不包含该 API，请检查 polyfill'
        );

        return {
            observe: function () { },
            unobserve: function () { }
        };
    }

    return new IntersectionObserver(observerHandler);
})();

export { LoadMoreHook };
